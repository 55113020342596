import * as React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Post } from '../components/post'
import { PostIndexQuery } from '../../graphql-types'

interface Props {
  data: PostIndexQuery;
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const posts = data.posts.nodes
  return (
    <Layout>
      <SEO title="Home" />
      <ul style={{ paddingLeft: 0 }}>
        { posts.map((post) => {
          if (
            !post.frontmatter ||
            !post.frontmatter.title ||
            !post.frontmatter.categories ||
            !post.excerpt ||
            !post.timeToRead ||
            !post.parent ||
            !post.parent.modifiedTime
          ) return null

          return (
            <li key={post.frontmatter.slug}>
              <Post
                url={post.frontmatter.slug}
                title={post.frontmatter.title}
                excerpt={post.excerpt}
                ttr={post.timeToRead}
                categories={post.frontmatter.categories}
                modifiedTime={post.parent.modifiedTime}
              />
            </li>
          )
        }) }
      </ul>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query PostIndex {
    posts: allMarkdownRemark(
      sort: {
        fields: creationDate,
        order: DESC
      },
      filter: {
        frontmatter: {
          status: {
            eq: "published"
          }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          slug
          categories
        }
        parent {
          ...on File {
            modifiedTime(formatString: "MMM. DD, YYYY")
          }
        }
        timeToRead
        excerpt
      }
    }
  }
`
