import * as React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'

import { Header } from '../components/typography'

const styleTtr = css`
  color: var(--color-foreground);
  opacity: 0.6;
  font-size: 1rem;
`

const styleHeader = css`
  text-decoration-color: var(--color-point);
`


interface Props {
  url: string;
  title: string;
  excerpt: string;
  ttr: number;
  categories: string[];
  modifiedTime: string;
}

export const Post: React.FC<Props> = ({
  url,
  title,
  excerpt,
  ttr,
  categories,
  modifiedTime,
}) => {
  return (
    <article>
      <Link className={styleHeader} to={url}>
        <Header>{title}</Header>
      </Link>
      <span className={styleTtr}>
        {ttr} mins 
        {categories.length > 0 ? ' · ' : ''} 
        {categories.join(', ')}
        {' · '}
        {modifiedTime}
      </span>
      <p>{excerpt}</p>
    </article>
  )
}